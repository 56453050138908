var name = 'DoublePage';

page[name] = class PageClassName extends page.PageClass{





    // init

    init(ajaxBool = false){

        super.init();



        // elements

        this.image = document.querySelector(".doublePage__image");



    }





    // show

    show(ajaxBool = false){

        super.show();
        
        if(this.image !== null){
            this.image.classList.add("show");
        }

        page.logo.classList.add("show");
        page.menuButton.classList.add("show");

        setTimeout(() => {

            page.helpers.showBorder(".doublePage__content .page__border");

            if(page.view){
                page.view.seek();
            }

        }, 125);

        return true;

    }





};

name = 'doublePage';
page.names.push(name);

page[name] = new page['DoublePage'];
page[name] = page[name].create();